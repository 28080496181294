@charset "utf-8";

@import '../../../common/css/function';

/* お問い合わせ */
#inquiry {
    .container {
        padding: spvw(70) spvw(20) spvw(100);
    }
    .body {
        background: $white;
        padding: spvw(70) spvw(20);
    }
	section {
        margin-top: spvw(100);
        &:first-of-type {
            margin-top: spvw(70);
        }
    }
    h2 {
        color: #cc0000;
        font-size: spvw(28);
        font-weight: bold;
        margin-bottom: spvw(50);
    }
    .p_txt {
        font-size: spvw(28);
        letter-spacing: 0;
        line-height: spvw(54);
        em {
            font-weight: bold;
        }
    }
    .p_txt_mail{
      margin-top: spvw(30);
    }
    .p_txt_inquiry1 {
        margin: spvw(80) 0 spvw(46);
    }
    .p_txt_inquiry2 {
        margin: 0 0 spvw(46);
    }
    .p_txt_inquiry3 {
        margin: 0 0 spvw(50);
    }
    .list {
        li {
            margin-top: spvw(64);
        }
    }
    .anchor {
        color: #323232;
        font-size: spvw(28);
        letter-spacing: 0;
        font-weight: bold;
        &:hover {
            text-decoration: none;
        }
        &::after {
            content: "→";
            font-size: spvw(28);
            color: #323232;
            margin: auto;
            padding-left: spvw(33);
        }
    }
    .externallink {
        font-weight: normal;
        &::after {
            position: relative;
            top: spvw(3);
            content: url("../img/icon_externallink.png");
            padding-top: spvw(2);
            padding-left: spvw(33);
        }                
    }
    .btn {
        border: 1px solid #cc0000;
        border-radius: 50px;
        margin: spvw(50) auto 0;
        text-align: center;
        a {
            position: relative;
            display: inline-block;
            padding: spvw(40) spvw(40) spvw(40) 0;
            color: #cc0000;
            text-align: center;
            span {
                font-size: spvw(24);
            }
            &::before {
                width: 16px;
                height: 1px;
                background: #cc0000;
            }
            &::after {
                width: 4px;
                height: 4px;
                border-top: 1px solid #cc0000;
                border-right: 1px solid #cc0000;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    @include mq(pc) {
        .container {
            padding: 70px 20px 90px;
        }
        .body {
            max-width: 960px;
            padding: 50px;
            margin: 0 auto;
        }
        section {
            margin-top: 80px;
            &:first-of-type {
                margin-top: 0;
            }
        }  
        h2 {
            font-size: 18px;
            margin-bottom: 1.333em;
        }    
        .p_txt {
            font-size: 16px;
            line-height: 2em;
        }
        .p_txt_mail {
            margin-top: 1em;
        }
        .p_txt_inquiry1 {
            margin: 3em 0 2em;
        }
        .p_txt_inquiry2 {
            margin: 0 0 1em;
        }
        .p_txt_inquiry3 {
            margin: 0 0 1em;
        }
        .list {
            li {
                font-size: 14px;
                margin-top: 1.929em;
                &:first-child {
                    margin-top: 2.071em;
                }
            }
        }
        .anchor {
            position: relative;
            font-size: 14px;
            &::after {
                font-size: 16px;
                padding-left: 1.063em;
                transition: .2s;
            }
            br {
                display: none;
            }
            &:before {
                position: absolute;
                content: "";
                bottom: -7px;
                width: 0;
                height: 1px;
                transition: .2s;
            }
            &:hover {
                text-decoration: none;
                &::after {
                    padding-left: 1.5em;
                }
                &::before {
                    width: 100%;
                    border-bottom: 1px solid #323232;
                    transition: .2s;
                }
            }
        }
        .externallink {
            font-size: 16px;
            &::after {
                top: .2em;
                padding-left: .1em;
            }
            &:hover {
                &::after {
                    padding-left: .1em;
                }
            }
        }
        .btn {
            margin: 25px 0 0;
            max-width: 350px;
            border-radius: 50px;
            transition: .2s;
            a {
              padding: 1em 1.5em 1em 0;
              font-size: 16px;
              span {
                  font-size: 12px;
              }
              &::before,
              &::after {
                  transition: .2s;
              }
            }
            &:hover {
                background: #cc0000;
                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 16px;
                    &::before {
                        background: $white;
                        right: -.5em;
                    }
                    &::after {
                        border-color: $white;
                        right: -.5em;
                    }
                }
            }
        }
    }
}

/* このサイトについて */
#about_site {
    .container {
        padding: spvw(70) spvw(20) spvw(100);
    }
    .body {
        background: $white;
        padding: spvw(70) spvw(20);
    }
	.section {
        margin-top: spvw(100);
        &:first-of-type {
            margin-top: 0;
        }
    }
    h2 {
        color: #cc0000;
        font-size: spvw(28);
        font-weight: bold;
        margin-bottom: spvw(50);
    }
    .p_txt {
        font-size: spvw(28);
        letter-spacing: 0;
        line-height: spvw(54);
        em {
            font-weight: bold;
        }
    }
    .p_txt_aboutsite1 {
        margin: spvw(80) 0 0;
    }
    .list {
        li {
            margin-top: spvw(64);
        }
    }
    .anchor {
        color: #323232;
        font-size: spvw(28);
        letter-spacing: 0;
        font-weight: bold;
        &:hover {
            text-decoration: none;
        }
        &::after {
            content: "→";
            font-size: spvw(28);
            color: #323232;
            margin: auto;
            padding-left: spvw(33);
        }
        &_txt {
            color: #0a5fc7;
            font-size: spvw(28);
            :hover {
                text-decoration: none;
            }
        }
    }
    .externallink {
        font-weight: normal;
        &::after {
            position: relative;
            top: spvw(3);
            content: url("../img/icon_externallink.png");
            padding-top: spvw(2);
            padding-left: spvw(33);
        }                
    }
    .btn {
        border: 1px solid #cc0000;
        border-radius: 50px;
        margin: spvw(50) auto 0;
        a {
            position: relative;
            display: block;
            padding: spvw(40) 0;
            color: #cc0000;
            text-align: center;
            span {
                font-size: spvw(24);
            }
            &::before {
                width: 16px;
                height: 1px;
                background: #cc0000;
            }
            &::after {
                width: 4px;
                height: 4px;
                border-top: 1px solid #cc0000;
                border-right: 1px solid #cc0000;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 53px;
                margin: auto;
                vertical-align: middle;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .note {
            li {
                line-height: spvw(54);
            }
        }
    }
    @include mq(pc) {
        .container {
            padding: 70px 20px 90px;
        }
        .body {
            max-width: 960px;
            padding: 50px;
            margin: 0 auto;
        }
        .section {
            margin-top: 80px;
            &:first-of-type {
                margin-top: 0;
            }
        }  
        h2 {
            font-size: 18px;
            margin-bottom: 1.333em;
        }    
        .p_txt {
            font-size: 16px;
            line-height: 2em;
        }
        .p_txt_aboutsite1 {
            margin: 2.125em 0 0;
        }
        .list {
            li {
                font-size: 14px;
                margin-top: 1.929em;
                &:first-child {
                    margin-top: 2.071em;
                }
            }
        }
        .anchor {
            position: relative;
            font-size: 14px;
            &::after {
                font-size: 16px;
                padding-left: 1.063em;
                transition: .2s;
            }
            br {
                display: none;
            }
            display: inline;
            background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
            transition: background .2s;
            &:hover {
                text-decoration: none;
                background-size: 100% 1px;
                padding-bottom: .2em;
                &::after {
                    padding-left: 2.063em;
                }
            }
            &_txt {
                position: relative;
                font-size: 16px;
                display: inline;
                background: linear-gradient(#0a5fc7, #0a5fc7) 0 100%/0 1px no-repeat;
                transition: background .2s;
                &:hover {
                    text-decoration: none;
                    background-size: 100% 1px;
                    padding-bottom: .2em;
                }
            }
        }
        .externallink {
            font-size: 16px;
            &::after {
                top: .2em;
                padding-left: 1em;
            }                
        }
        .btn {
            margin: 25px 0 0;
            max-width: 350px;
            border-radius: 50px;
            transition: .2s;
            a {
                &::before,
                &::after {
                    transition: .2s;
                }
            }
            &:hover {
                background: #cc0000;
                a {
                    color: $white;
                    text-decoration: none;
                    font-size: 16px;
                    padding: 1em 0;
                    &::before {
                        background: $white;
                        right: 48px;
                    }
                    &::after {
                        border-color: $white;
                        right: 48px;
                    }
                }
            }
            a {
                padding: 1em 0;
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
        }
        .note {
            li {
                line-height: 2em;
            }
        }
    }
}

/* サイトマップ */
#sitemap {
    .cap_box {
        padding: 0 spvw(20);
    }
    .cap_main {
        font-size: spvw(28);
        font-weight: bold;
        a {
            color: #323232;
            font-size: spvw(28);
            letter-spacing: 0;
            &:hover {
                text-decoration: none;
            }
            &::after {
                content: "→";
                font-size: spvw(28);
                font-weight: bold;
                color: #323232;
                margin: auto;
                padding-left: spvw(33);
            }
        }
    }
    .container {
        padding: spvw(70) spvw(20) spvw(100);
    }
    .body {
        background: $white;
        padding: spvw(70) spvw(20);
    }
    hr {
        display: block;
        margin: spvw(70) spvw(20);
        background-color: #cfcfcf;
        border: 0;
        height: 1px;
    }
    .list {
        padding: spvw(47) spvw(20) 0;
        &:last-of-type {
            padding: 0 spvw(20);            
        }
        li {
            margin-left: spvw(30);
            margin-bottom: spvw(57);
            text-indent: spvw(-30);
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: #323232;
                font-size: spvw(28);
                letter-spacing: 0;
                &:hover {
                    text-decoration: none;
                }
                &::after {
                    content: "→";
                    font-size: spvw(28);
                    color: #323232;
                    margin: auto;
                    padding-left: spvw(33);
                }
            }
            .externallink {
                &::after {
                    position: relative;
                    top: spvw(3);
                    content: url("../img/icon_externallink.png");
                    padding-top: spvw(2);
                    padding-left: spvw(33);
                }                
            }
        }
        &_secondary {
            flex-direction: column;
            padding: 0;
            li {
                width: auto;
            }
        }
    }
    @include mq(pc) {
        .cap_box {
            padding: 0;
        }
        .cap_main {
            font-size: 18px;
            a {
                position: relative;
                font-size: 18px;
                background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
                transition: background .2s;
                &::after {
                    font-size: 16px;
                    padding-left: 1.063em;
                    transition: .2s;
                }
                &:hover {
                    background-size: 100% 1px;
                    padding-bottom: .2em;
                    &::after {
                        padding-left: 1.5em;
                    }
                }
            }
        }
        .container {
            padding: 70px 20px 90px;
        }
        .body {
            max-width: 1160px;
            padding: 70px 50px;
            margin: 0 auto;
        }
        hr {
            margin: 70px 0;
        }
        .list {
            padding: 1.667em 0 0;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 1.875em 3.625em;
            &:last-of-type {
                padding: 0;
            }
            li {
                position: relative;
                width: 28%;
                margin-left: 15px;
                margin-bottom: 0;
                text-indent: -15px;
                &:last-child {
                    margin-bottom: 0;
                }
                a {
                    position: relative;
                    font-size: 16px;
                    line-height: 2em;
                    display: inline;
                    background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
                    transition: background .2s;
                    letter-spacing: -.01em;
                    padding-bottom: .2em;
                    &::after {
                        font-size: 16px;
                        padding-left: 1.063em;
                        transition: .2s;
                    }
                    br {
                        display: none;
                    }
                    &:hover {
                        background-size: 100% 1px;
                        &::after {
                            padding-left: 1.5em;
                        }
                    }
                    &.pdf {
                        &:hover {
                        }
                        &::after {
                            content: "";
                            bottom: .15em;
                        }
                    }
                }
                .externallink {
                    &::after {
                        top: .2em;
                        padding-left: 1.063em;
                    }   
                    &:hover {
                        &::after {
                            padding-left: 1.063em;
                        }
                    }             
                }
            }
        }
    }
}

/* 旅程管理研修 */
#training {
    .container {
        padding: spvw(70) spvw(20) spvw(100);
    }
    .body {
        background: $white;
        padding: spvw(70) spvw(20);
    }
	.section {
        margin-top: spvw(100);
        &:first-of-type {
            margin-top: 0;
        }
    }
    h2 {
        color: #cc0000;
        font-size: spvw(28);
        font-weight: bold;
        margin-bottom: spvw(50);
    }
    .p_txt {
        font-size: spvw(28);
        letter-spacing: 0;
        line-height: spvw(54);
        em {
            font-weight: bold;
            padding-right: spvw(10);
        }
    }
    .list {
        li {
            margin-top: spvw(64);
            a {
                color: #323232;
                text-decoration: none;
            }
            .externallink {
                &::after {
                    position: relative;
                    top: spvw(3);
                    content: url("../img/icon_externallink.png");
                    padding-top: spvw(2);
                    padding-left: spvw(33);
                }                
            }
        }
    }
    @include mq(pc) {
        .container {
            padding: 70px 20px 90px;
        }
        .body {
            max-width: 960px;
            padding: 50px;
            margin: 0 auto;
        }
        .section {
            margin-top: 80px;
            &:first-of-type {
                margin-top: 0;
            }
        }  
        h2 {
            font-size: 18px;
            margin-bottom: 1.333em;
        }
        .p_txt {
            font-size: 16px;
            line-height: 2em;
            em {
                font-size: 18px;
                padding-right: .5em;
            }
        }
        .list {
            li {
                font-size: 14px;
                margin-top: 1.929em;
                &:first-child {
                    margin-top: 2.071em;
                }
                a {
                    position: relative;
                    font-size: 16px;
                    line-height: 2em;
                    display: inline;
                    background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
                    transition: background .2s;
                    &::after {
                        font-size: 16px;
                        padding-left: 1.063em;
                        transition: .2s;
                    }
                    br {
                        display: none;
                    }
                    &:hover {
                        background-size: 100% 1px;
                        padding-bottom: .2em;
                        &::after {
                            padding-left: 1.5em;
                        }
                    }
                }
                .externallink {
                    &::after {
                        top: .2em;
                        padding-left: 1.063em;
                    }
                    &:hover {
                        &::after {
                            padding-left: 1.063em;
                        }
                    }
                }
            }
        }
    }	
}



/* 404エラーページ */
#notfound {
    .notfound_box {
        text-align: center;
    }
    .notfound_main {
        font-size: spvw(40);
        font-weight: bold;
        letter-spacing: 0;
        em {
            display: block;
            font-family: 'Open Sans', sans-serif;
            font-size: spvw(28);
            font-weight: normal;
            margin: spvw(41) 0 spvw(75);
        }
    }
    .body {
        background: $white;
        padding: spvw(100) spvw(40);
    }
    .body_search {
        padding: 0 spvw(40) spvw(100);
    }
    .anchor {
        color: #323232;
        font-size: spvw(28);
        letter-spacing: 0;
        font-weight: bold;
        &:hover {
            text-decoration: none;
        }
        &::after {
            content: "→";
            font-size: spvw(28);
            color: #323232;
            margin: auto;
            padding-left: spvw(30);
        }
        &_txt {
            color: #0a5fc7;
            font-size: spvw(28);
            :hover {
                text-decoration: none;
            }
        }
    }
    .user_list {
        margin: spvw(100) 0;
        .corporation,
        .individual {
            margin-bottom: spvw(4);            
        }
        .corporation,
        .individual,
        .partner {
            a {
                display: block;
                padding: spvw(42) spvw(41) spvw(42) spvw(40);
                p {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        display: inline-block;
                        color: $white;
                        font-size: spvw(30);
                    }
                    i {
                        position: relative;
                        display: inline-block;
                        width: spvw(81);
                        height: spvw(18);
                        border-bottom: 1px solid $white;
                        &::after {
                            position: absolute;
                            content: "";
                            width: spvw(27);
                            height: 1px;
                            border-top: 1px solid $white;
                            transform: rotate(30deg);
                            right: 0;
                            top: spvw(8.5);
                        }
                    }
                }
            }
        }
        .corporation {
            a {
                background: linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(155,155,155,1) 100%);
            }
        }
        .individual {
            a {
                background: linear-gradient(90deg, rgba(204,0,0,1) 0%, rgba(235,85,20,1) 100%);
            }
        }
        .partner {
            a {
                background: linear-gradient(90deg, rgba(0,28,123,1) 0%, rgba(0,166,225,1) 100%);
            }
        }
    }
    .p_txt {
        font-size: spvw(28);
        letter-spacing: 0;
        line-height: spvw(54);
    }
    .p_txt_search {
        padding: spvw(99) 0 spvw(69);
    }
    .search {
        &_keyword {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            
            &_submit {
                position: absolute;
                top: 50%;
                right: 2em;
                transform: translateY(-50%);
                width: 1.75em;
                height: 1.75em;
                padding: 0;
                text-indent: -9999px;
                cursor: pointer;
                border: none;
                z-index: +2;
                background: transparent url(/common/img/icon/search_red.svg) center center / 1.7em no-repeat;
                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
        &_input {
            &_keyword {
                display: block;
                width: 100%;
                background: $white;
                border: none;
                -webkit-appearance: none;
                appearance: none;
                border-radius: 50px;
                box-shadow: 0px 8px 10px -10px $black;
                margin: spvw(15) auto 0;
                padding: spvw(40);
                box-sizing: border-box;
                font-size: spvw(28);
                &::placeholder {
                    font-size: spvw(28);
                }
                &:focus {
                    box-shadow: 0px 8px 10px -10px #666;
                    border: none;
                    outline: none;
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
    }
    .list {
        li {
            &:first-child {
                margin-bottom: spvw(58);
            }
        }
    }
    @include mq(pc) {
        .notfound_main {
            font-size: 24px;
            em {
                font-size: 18px;
                margin: 30px 0 54px;
            }
        }
        .body {
            padding: 70px 20px;
        }
        .body_search {
            max-width: 1010px;
            margin: 0 auto;
            padding: 0 0 90px;
        }
        .user_list {
            display: flex;
            justify-content: center;
            gap: 0 30px;
            margin: 70px auto 50px;
            padding: 0;
            .corporation,
            .individual,
            .partner {
                height: 87px;
                width: 32%;
                max-width: 317px;
            }
            .corporation,
            .individual,
            .partner {
                a {
                    padding: 2.333em 2.133em 2.467em 2.267em;
                    p {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            font-size: 15px;
                            letter-spacing: 0;
                        }
                        i {
                            position: relative;
                            width: 40px;
                            height: 10px;
                            left: 0;
                            transition: left .2s;
                            &::after {
                                width: 13px;
                                height: 1px;
                                right: 0;
                                top: 6px;
                            }
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        p {
                            i {
                                left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .list {
            li {
                &:first-child {
                    margin-bottom: 2.143em;
                }
            }
        }
        .anchor {
            position: relative;
            font-size: 14px;
            &::after {
                font-size: 16px;
                padding-left: 1.063em;
                transition: .2s;
            }
            br {
                display: none;
            }
            &:before {
                position: absolute;
                content: "";
                bottom: -7px;
                width: 0;
                height: 1px;
                transition: .2s;
            }
            &:hover {
                text-decoration: none;
                &::before {
                    width: 100%;
                    border-bottom: 1px solid #323232;
                    transition: .2s;
                }
                &::after {
                    padding-left: 2.063em;                    
                }
            }
            &_txt {
                position: relative;
                font-size: 16px;
                &:before {
                    position: absolute;
                    content: "";
                    bottom: -5px;
                    width: 0;
                    height: 1px;
                    transition: .2s;
                }
                &:hover {
                    text-decoration: none;
                    &::before {
                        width: 100%;
                        border-bottom: 1px solid #0a5fc7;
                        transition: .2s;
                    }
                }
            }
        }
        .p_txt {
            font-size: 16px;
            line-height: 2em;
            text-align: center;
        }
        .p_txt_search {
            padding: 4.375em 0 1.875em;
        }
        .search {
            &_keyword {
                max-width: 660px;
                margin: 0 auto;
                &_submit {
                    right: 2em;
                }
            }
            &_input {
                &_keyword {
                    width: 100%;
                    margin: 0;
                    padding: .93em 2.5em;
                    outline: none;
                    font-size: 16px;
                    &::placeholder {
                        font-size: 16px;
                    }
                    &:focus::placeholder {
                        color: transparent;
                    }
                }
            }
        }
    }
}

/* 英語用 */
html[lang="en"] {
    #notfound {
        .notfound_main {
            margin: 0 0 spvw(75);
        }
    }
    @include mq(pc) {
        #notfound {
            .notfound_main {
                margin: 0 0 54px;
            }
        }   
    }
}