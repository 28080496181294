@charset "UTF-8";
/* 共通カルーセル用ドットアニメーション */
@keyframes dotsanime {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.clearText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

/* お問い合わせ */
#inquiry .container {
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw) calc((100 / 750) * 100vw); }

#inquiry .body {
  background: white;
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw); }

#inquiry section {
  margin-top: calc((100 / 750) * 100vw); }
  #inquiry section:first-of-type {
    margin-top: calc((70 / 750) * 100vw); }

#inquiry h2 {
  color: #cc0000;
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold;
  margin-bottom: calc((50 / 750) * 100vw); }

#inquiry .p_txt {
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  line-height: calc((54 / 750) * 100vw); }
  #inquiry .p_txt em {
    font-weight: bold; }

#inquiry .p_txt_mail {
  margin-top: calc((30 / 750) * 100vw); }

#inquiry .p_txt_inquiry1 {
  margin: calc((80 / 750) * 100vw) 0 calc((46 / 750) * 100vw); }

#inquiry .p_txt_inquiry2 {
  margin: 0 0 calc((46 / 750) * 100vw); }

#inquiry .p_txt_inquiry3 {
  margin: 0 0 calc((50 / 750) * 100vw); }

#inquiry .list li {
  margin-top: calc((64 / 750) * 100vw); }

#inquiry .anchor {
  color: #323232;
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  font-weight: bold; }
  #inquiry .anchor:hover {
    text-decoration: none; }
  #inquiry .anchor::after {
    content: "→";
    font-size: calc((28 / 750) * 100vw);
    color: #323232;
    margin: auto;
    padding-left: calc((33 / 750) * 100vw); }

#inquiry .externallink {
  font-weight: normal; }
  #inquiry .externallink::after {
    position: relative;
    top: calc((3 / 750) * 100vw);
    content: url("../img/icon_externallink.png");
    padding-top: calc((2 / 750) * 100vw);
    padding-left: calc((33 / 750) * 100vw); }

#inquiry .btn {
  border: 1px solid #cc0000;
  border-radius: 50px;
  margin: calc((50 / 750) * 100vw) auto 0;
  text-align: center; }
  #inquiry .btn a {
    position: relative;
    display: inline-block;
    padding: calc((40 / 750) * 100vw) calc((40 / 750) * 100vw) calc((40 / 750) * 100vw) 0;
    color: #cc0000;
    text-align: center; }
    #inquiry .btn a span {
      font-size: calc((24 / 750) * 100vw); }
    #inquiry .btn a::before {
      width: 16px;
      height: 1px;
      background: #cc0000; }
    #inquiry .btn a::after {
      width: 4px;
      height: 4px;
      border-top: 1px solid #cc0000;
      border-right: 1px solid #cc0000;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    #inquiry .btn a::before, #inquiry .btn a::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      vertical-align: middle; }
    #inquiry .btn a:hover {
      text-decoration: none; }

@media screen and (min-width: 750.1px) {
  #inquiry .container {
    padding: 70px 20px 90px; }
  #inquiry .body {
    max-width: 960px;
    padding: 50px;
    margin: 0 auto; }
  #inquiry section {
    margin-top: 80px; }
    #inquiry section:first-of-type {
      margin-top: 0; }
  #inquiry h2 {
    font-size: 18px;
    margin-bottom: 1.333em; }
  #inquiry .p_txt {
    font-size: 16px;
    line-height: 2em; }
  #inquiry .p_txt_mail {
    margin-top: 1em; }
  #inquiry .p_txt_inquiry1 {
    margin: 3em 0 2em; }
  #inquiry .p_txt_inquiry2 {
    margin: 0 0 1em; }
  #inquiry .p_txt_inquiry3 {
    margin: 0 0 1em; }
  #inquiry .list li {
    font-size: 14px;
    margin-top: 1.929em; }
    #inquiry .list li:first-child {
      margin-top: 2.071em; }
  #inquiry .anchor {
    position: relative;
    font-size: 14px; }
    #inquiry .anchor::after {
      font-size: 16px;
      padding-left: 1.063em;
      transition: .2s; }
    #inquiry .anchor br {
      display: none; }
    #inquiry .anchor:before {
      position: absolute;
      content: "";
      bottom: -7px;
      width: 0;
      height: 1px;
      transition: .2s; }
    #inquiry .anchor:hover {
      text-decoration: none; }
      #inquiry .anchor:hover::after {
        padding-left: 1.5em; }
      #inquiry .anchor:hover::before {
        width: 100%;
        border-bottom: 1px solid #323232;
        transition: .2s; }
  #inquiry .externallink {
    font-size: 16px; }
    #inquiry .externallink::after {
      top: .2em;
      padding-left: .1em; }
    #inquiry .externallink:hover::after {
      padding-left: .1em; }
  #inquiry .btn {
    margin: 25px 0 0;
    max-width: 350px;
    border-radius: 50px;
    transition: .2s; }
    #inquiry .btn a {
      padding: 1em 1.5em 1em 0;
      font-size: 16px; }
      #inquiry .btn a span {
        font-size: 12px; }
      #inquiry .btn a::before, #inquiry .btn a::after {
        transition: .2s; }
    #inquiry .btn:hover {
      background: #cc0000; }
      #inquiry .btn:hover a {
        color: white;
        text-decoration: none;
        font-size: 16px; }
        #inquiry .btn:hover a::before {
          background: white;
          right: -.5em; }
        #inquiry .btn:hover a::after {
          border-color: white;
          right: -.5em; } }

/* このサイトについて */
#about_site .container {
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw) calc((100 / 750) * 100vw); }

#about_site .body {
  background: white;
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw); }

#about_site .section {
  margin-top: calc((100 / 750) * 100vw); }
  #about_site .section:first-of-type {
    margin-top: 0; }

#about_site h2 {
  color: #cc0000;
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold;
  margin-bottom: calc((50 / 750) * 100vw); }

#about_site .p_txt {
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  line-height: calc((54 / 750) * 100vw); }
  #about_site .p_txt em {
    font-weight: bold; }

#about_site .p_txt_aboutsite1 {
  margin: calc((80 / 750) * 100vw) 0 0; }

#about_site .list li {
  margin-top: calc((64 / 750) * 100vw); }

#about_site .anchor {
  color: #323232;
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  font-weight: bold; }
  #about_site .anchor:hover {
    text-decoration: none; }
  #about_site .anchor::after {
    content: "→";
    font-size: calc((28 / 750) * 100vw);
    color: #323232;
    margin: auto;
    padding-left: calc((33 / 750) * 100vw); }
  #about_site .anchor_txt {
    color: #0a5fc7;
    font-size: calc((28 / 750) * 100vw); }
    #about_site .anchor_txt :hover {
      text-decoration: none; }

#about_site .externallink {
  font-weight: normal; }
  #about_site .externallink::after {
    position: relative;
    top: calc((3 / 750) * 100vw);
    content: url("../img/icon_externallink.png");
    padding-top: calc((2 / 750) * 100vw);
    padding-left: calc((33 / 750) * 100vw); }

#about_site .btn {
  border: 1px solid #cc0000;
  border-radius: 50px;
  margin: calc((50 / 750) * 100vw) auto 0; }
  #about_site .btn a {
    position: relative;
    display: block;
    padding: calc((40 / 750) * 100vw) 0;
    color: #cc0000;
    text-align: center; }
    #about_site .btn a span {
      font-size: calc((24 / 750) * 100vw); }
    #about_site .btn a::before {
      width: 16px;
      height: 1px;
      background: #cc0000; }
    #about_site .btn a::after {
      width: 4px;
      height: 4px;
      border-top: 1px solid #cc0000;
      border-right: 1px solid #cc0000;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    #about_site .btn a::before, #about_site .btn a::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 53px;
      margin: auto;
      vertical-align: middle; }
    #about_site .btn a:hover {
      text-decoration: none; }
  #about_site .btn .note li {
    line-height: calc((54 / 750) * 100vw); }

@media screen and (min-width: 750.1px) {
  #about_site .container {
    padding: 70px 20px 90px; }
  #about_site .body {
    max-width: 960px;
    padding: 50px;
    margin: 0 auto; }
  #about_site .section {
    margin-top: 80px; }
    #about_site .section:first-of-type {
      margin-top: 0; }
  #about_site h2 {
    font-size: 18px;
    margin-bottom: 1.333em; }
  #about_site .p_txt {
    font-size: 16px;
    line-height: 2em; }
  #about_site .p_txt_aboutsite1 {
    margin: 2.125em 0 0; }
  #about_site .list li {
    font-size: 14px;
    margin-top: 1.929em; }
    #about_site .list li:first-child {
      margin-top: 2.071em; }
  #about_site .anchor {
    position: relative;
    font-size: 14px;
    display: inline;
    background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
    transition: background .2s; }
    #about_site .anchor::after {
      font-size: 16px;
      padding-left: 1.063em;
      transition: .2s; }
    #about_site .anchor br {
      display: none; }
    #about_site .anchor:hover {
      text-decoration: none;
      background-size: 100% 1px;
      padding-bottom: .2em; }
      #about_site .anchor:hover::after {
        padding-left: 2.063em; }
    #about_site .anchor_txt {
      position: relative;
      font-size: 16px;
      display: inline;
      background: linear-gradient(#0a5fc7, #0a5fc7) 0 100%/0 1px no-repeat;
      transition: background .2s; }
      #about_site .anchor_txt:hover {
        text-decoration: none;
        background-size: 100% 1px;
        padding-bottom: .2em; }
  #about_site .externallink {
    font-size: 16px; }
    #about_site .externallink::after {
      top: .2em;
      padding-left: 1em; }
  #about_site .btn {
    margin: 25px 0 0;
    max-width: 350px;
    border-radius: 50px;
    transition: .2s; }
    #about_site .btn a::before, #about_site .btn a::after {
      transition: .2s; }
    #about_site .btn:hover {
      background: #cc0000; }
      #about_site .btn:hover a {
        color: white;
        text-decoration: none;
        font-size: 16px;
        padding: 1em 0; }
        #about_site .btn:hover a::before {
          background: white;
          right: 48px; }
        #about_site .btn:hover a::after {
          border-color: white;
          right: 48px; }
    #about_site .btn a {
      padding: 1em 0;
      font-size: 16px; }
      #about_site .btn a span {
        font-size: 12px; }
  #about_site .note li {
    line-height: 2em; } }

/* サイトマップ */
#sitemap .cap_box {
  padding: 0 calc((20 / 750) * 100vw); }

#sitemap .cap_main {
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold; }
  #sitemap .cap_main a {
    color: #323232;
    font-size: calc((28 / 750) * 100vw);
    letter-spacing: 0; }
    #sitemap .cap_main a:hover {
      text-decoration: none; }
    #sitemap .cap_main a::after {
      content: "→";
      font-size: calc((28 / 750) * 100vw);
      font-weight: bold;
      color: #323232;
      margin: auto;
      padding-left: calc((33 / 750) * 100vw); }

#sitemap .container {
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw) calc((100 / 750) * 100vw); }

#sitemap .body {
  background: white;
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw); }

#sitemap hr {
  display: block;
  margin: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw);
  background-color: #cfcfcf;
  border: 0;
  height: 1px; }

#sitemap .list {
  padding: calc((47 / 750) * 100vw) calc((20 / 750) * 100vw) 0; }
  #sitemap .list:last-of-type {
    padding: 0 calc((20 / 750) * 100vw); }
  #sitemap .list li {
    margin-left: calc((30 / 750) * 100vw);
    margin-bottom: calc((57 / 750) * 100vw);
    text-indent: calc((-30 / 750) * 100vw); }
    #sitemap .list li:last-child {
      margin-bottom: 0; }
    #sitemap .list li a {
      color: #323232;
      font-size: calc((28 / 750) * 100vw);
      letter-spacing: 0; }
      #sitemap .list li a:hover {
        text-decoration: none; }
      #sitemap .list li a::after {
        content: "→";
        font-size: calc((28 / 750) * 100vw);
        color: #323232;
        margin: auto;
        padding-left: calc((33 / 750) * 100vw); }
    #sitemap .list li .externallink::after {
      position: relative;
      top: calc((3 / 750) * 100vw);
      content: url("../img/icon_externallink.png");
      padding-top: calc((2 / 750) * 100vw);
      padding-left: calc((33 / 750) * 100vw); }
  #sitemap .list_secondary {
    flex-direction: column;
    padding: 0; }
    #sitemap .list_secondary li {
      width: auto; }

@media screen and (min-width: 750.1px) {
  #sitemap .cap_box {
    padding: 0; }
  #sitemap .cap_main {
    font-size: 18px; }
    #sitemap .cap_main a {
      position: relative;
      font-size: 18px;
      background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
      transition: background .2s; }
      #sitemap .cap_main a::after {
        font-size: 16px;
        padding-left: 1.063em;
        transition: .2s; }
      #sitemap .cap_main a:hover {
        background-size: 100% 1px;
        padding-bottom: .2em; }
        #sitemap .cap_main a:hover::after {
          padding-left: 1.5em; }
  #sitemap .container {
    padding: 70px 20px 90px; }
  #sitemap .body {
    max-width: 1160px;
    padding: 70px 50px;
    margin: 0 auto; }
  #sitemap hr {
    margin: 70px 0; }
  #sitemap .list {
    padding: 1.667em 0 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1.875em 3.625em; }
    #sitemap .list:last-of-type {
      padding: 0; }
    #sitemap .list li {
      position: relative;
      width: 28%;
      margin-left: 15px;
      margin-bottom: 0;
      text-indent: -15px; }
      #sitemap .list li:last-child {
        margin-bottom: 0; }
      #sitemap .list li a {
        position: relative;
        font-size: 16px;
        line-height: 2em;
        display: inline;
        background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
        transition: background .2s;
        letter-spacing: -.01em;
        padding-bottom: .2em; }
        #sitemap .list li a::after {
          font-size: 16px;
          padding-left: 1.063em;
          transition: .2s; }
        #sitemap .list li a br {
          display: none; }
        #sitemap .list li a:hover {
          background-size: 100% 1px; }
          #sitemap .list li a:hover::after {
            padding-left: 1.5em; }
        #sitemap .list li a.pdf::after {
          content: "";
          bottom: .15em; }
      #sitemap .list li .externallink::after {
        top: .2em;
        padding-left: 1.063em; }
      #sitemap .list li .externallink:hover::after {
        padding-left: 1.063em; } }

/* 旅程管理研修 */
#training .container {
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw) calc((100 / 750) * 100vw); }

#training .body {
  background: white;
  padding: calc((70 / 750) * 100vw) calc((20 / 750) * 100vw); }

#training .section {
  margin-top: calc((100 / 750) * 100vw); }
  #training .section:first-of-type {
    margin-top: 0; }

#training h2 {
  color: #cc0000;
  font-size: calc((28 / 750) * 100vw);
  font-weight: bold;
  margin-bottom: calc((50 / 750) * 100vw); }

#training .p_txt {
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  line-height: calc((54 / 750) * 100vw); }
  #training .p_txt em {
    font-weight: bold;
    padding-right: calc((10 / 750) * 100vw); }

#training .list li {
  margin-top: calc((64 / 750) * 100vw); }
  #training .list li a {
    color: #323232;
    text-decoration: none; }
  #training .list li .externallink::after {
    position: relative;
    top: calc((3 / 750) * 100vw);
    content: url("../img/icon_externallink.png");
    padding-top: calc((2 / 750) * 100vw);
    padding-left: calc((33 / 750) * 100vw); }

@media screen and (min-width: 750.1px) {
  #training .container {
    padding: 70px 20px 90px; }
  #training .body {
    max-width: 960px;
    padding: 50px;
    margin: 0 auto; }
  #training .section {
    margin-top: 80px; }
    #training .section:first-of-type {
      margin-top: 0; }
  #training h2 {
    font-size: 18px;
    margin-bottom: 1.333em; }
  #training .p_txt {
    font-size: 16px;
    line-height: 2em; }
    #training .p_txt em {
      font-size: 18px;
      padding-right: .5em; }
  #training .list li {
    font-size: 14px;
    margin-top: 1.929em; }
    #training .list li:first-child {
      margin-top: 2.071em; }
    #training .list li a {
      position: relative;
      font-size: 16px;
      line-height: 2em;
      display: inline;
      background: linear-gradient(#323232, #323232) 0 100%/0 1px no-repeat;
      transition: background .2s; }
      #training .list li a::after {
        font-size: 16px;
        padding-left: 1.063em;
        transition: .2s; }
      #training .list li a br {
        display: none; }
      #training .list li a:hover {
        background-size: 100% 1px;
        padding-bottom: .2em; }
        #training .list li a:hover::after {
          padding-left: 1.5em; }
    #training .list li .externallink::after {
      top: .2em;
      padding-left: 1.063em; }
    #training .list li .externallink:hover::after {
      padding-left: 1.063em; } }

/* 404エラーページ */
#notfound .notfound_box {
  text-align: center; }

#notfound .notfound_main {
  font-size: calc((40 / 750) * 100vw);
  font-weight: bold;
  letter-spacing: 0; }
  #notfound .notfound_main em {
    display: block;
    font-family: 'Open Sans', sans-serif;
    font-size: calc((28 / 750) * 100vw);
    font-weight: normal;
    margin: calc((41 / 750) * 100vw) 0 calc((75 / 750) * 100vw); }

#notfound .body {
  background: white;
  padding: calc((100 / 750) * 100vw) calc((40 / 750) * 100vw); }

#notfound .body_search {
  padding: 0 calc((40 / 750) * 100vw) calc((100 / 750) * 100vw); }

#notfound .anchor {
  color: #323232;
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  font-weight: bold; }
  #notfound .anchor:hover {
    text-decoration: none; }
  #notfound .anchor::after {
    content: "→";
    font-size: calc((28 / 750) * 100vw);
    color: #323232;
    margin: auto;
    padding-left: calc((30 / 750) * 100vw); }
  #notfound .anchor_txt {
    color: #0a5fc7;
    font-size: calc((28 / 750) * 100vw); }
    #notfound .anchor_txt :hover {
      text-decoration: none; }

#notfound .user_list {
  margin: calc((100 / 750) * 100vw) 0; }
  #notfound .user_list .corporation,
  #notfound .user_list .individual {
    margin-bottom: calc((4 / 750) * 100vw); }
  #notfound .user_list .corporation a,
  #notfound .user_list .individual a,
  #notfound .user_list .partner a {
    display: block;
    padding: calc((42 / 750) * 100vw) calc((41 / 750) * 100vw) calc((42 / 750) * 100vw) calc((40 / 750) * 100vw); }
    #notfound .user_list .corporation a p,
    #notfound .user_list .individual a p,
    #notfound .user_list .partner a p {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      #notfound .user_list .corporation a p span,
      #notfound .user_list .individual a p span,
      #notfound .user_list .partner a p span {
        display: inline-block;
        color: white;
        font-size: calc((30 / 750) * 100vw); }
      #notfound .user_list .corporation a p i,
      #notfound .user_list .individual a p i,
      #notfound .user_list .partner a p i {
        position: relative;
        display: inline-block;
        width: calc((81 / 750) * 100vw);
        height: calc((18 / 750) * 100vw);
        border-bottom: 1px solid white; }
        #notfound .user_list .corporation a p i::after,
        #notfound .user_list .individual a p i::after,
        #notfound .user_list .partner a p i::after {
          position: absolute;
          content: "";
          width: calc((27 / 750) * 100vw);
          height: 1px;
          border-top: 1px solid white;
          transform: rotate(30deg);
          right: 0;
          top: calc((8.5 / 750) * 100vw); }
  #notfound .user_list .corporation a {
    background: linear-gradient(90deg, #323232 0%, #9b9b9b 100%); }
  #notfound .user_list .individual a {
    background: linear-gradient(90deg, #cc0000 0%, #eb5514 100%); }
  #notfound .user_list .partner a {
    background: linear-gradient(90deg, #001c7b 0%, #00a6e1 100%); }

#notfound .p_txt {
  font-size: calc((28 / 750) * 100vw);
  letter-spacing: 0;
  line-height: calc((54 / 750) * 100vw); }

#notfound .p_txt_search {
  padding: calc((99 / 750) * 100vw) 0 calc((69 / 750) * 100vw); }

#notfound .search_keyword {
  position: relative;
  width: 100%;
  height: 100%;
  display: block; }
  #notfound .search_keyword_submit {
    position: absolute;
    top: 50%;
    right: 2em;
    transform: translateY(-50%);
    width: 1.75em;
    height: 1.75em;
    padding: 0;
    text-indent: -9999px;
    cursor: pointer;
    border: none;
    z-index: +2;
    background: transparent url(/common/img/icon/search_red.svg) center center/1.7em no-repeat; }
    #notfound .search_keyword_submit:focus {
      border: none;
      outline: none; }

#notfound .search_input_keyword {
  display: block;
  width: 100%;
  background: white;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50px;
  box-shadow: 0px 8px 10px -10px black;
  margin: calc((15 / 750) * 100vw) auto 0;
  padding: calc((40 / 750) * 100vw);
  box-sizing: border-box;
  font-size: calc((28 / 750) * 100vw); }
  #notfound .search_input_keyword::placeholder {
    font-size: calc((28 / 750) * 100vw); }
  #notfound .search_input_keyword:focus {
    box-shadow: 0px 8px 10px -10px #666;
    border: none;
    outline: none; }
    #notfound .search_input_keyword:focus::placeholder {
      color: transparent; }

#notfound .list li:first-child {
  margin-bottom: calc((58 / 750) * 100vw); }

@media screen and (min-width: 750.1px) {
  #notfound .notfound_main {
    font-size: 24px; }
    #notfound .notfound_main em {
      font-size: 18px;
      margin: 30px 0 54px; }
  #notfound .body {
    padding: 70px 20px; }
  #notfound .body_search {
    max-width: 1010px;
    margin: 0 auto;
    padding: 0 0 90px; }
  #notfound .user_list {
    display: flex;
    justify-content: center;
    gap: 0 30px;
    margin: 70px auto 50px;
    padding: 0; }
    #notfound .user_list .corporation,
    #notfound .user_list .individual,
    #notfound .user_list .partner {
      height: 87px;
      width: 32%;
      max-width: 317px; }
    #notfound .user_list .corporation a,
    #notfound .user_list .individual a,
    #notfound .user_list .partner a {
      padding: 2.333em 2.133em 2.467em 2.267em; }
      #notfound .user_list .corporation a p,
      #notfound .user_list .individual a p,
      #notfound .user_list .partner a p {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        #notfound .user_list .corporation a p span,
        #notfound .user_list .individual a p span,
        #notfound .user_list .partner a p span {
          font-size: 15px;
          letter-spacing: 0; }
        #notfound .user_list .corporation a p i,
        #notfound .user_list .individual a p i,
        #notfound .user_list .partner a p i {
          position: relative;
          width: 40px;
          height: 10px;
          left: 0;
          transition: left .2s; }
          #notfound .user_list .corporation a p i::after,
          #notfound .user_list .individual a p i::after,
          #notfound .user_list .partner a p i::after {
            width: 13px;
            height: 1px;
            right: 0;
            top: 6px; }
      #notfound .user_list .corporation a:hover,
      #notfound .user_list .individual a:hover,
      #notfound .user_list .partner a:hover {
        text-decoration: none; }
        #notfound .user_list .corporation a:hover p i,
        #notfound .user_list .individual a:hover p i,
        #notfound .user_list .partner a:hover p i {
          left: 10px; }
  #notfound .list li:first-child {
    margin-bottom: 2.143em; }
  #notfound .anchor {
    position: relative;
    font-size: 14px; }
    #notfound .anchor::after {
      font-size: 16px;
      padding-left: 1.063em;
      transition: .2s; }
    #notfound .anchor br {
      display: none; }
    #notfound .anchor:before {
      position: absolute;
      content: "";
      bottom: -7px;
      width: 0;
      height: 1px;
      transition: .2s; }
    #notfound .anchor:hover {
      text-decoration: none; }
      #notfound .anchor:hover::before {
        width: 100%;
        border-bottom: 1px solid #323232;
        transition: .2s; }
      #notfound .anchor:hover::after {
        padding-left: 2.063em; }
    #notfound .anchor_txt {
      position: relative;
      font-size: 16px; }
      #notfound .anchor_txt:before {
        position: absolute;
        content: "";
        bottom: -5px;
        width: 0;
        height: 1px;
        transition: .2s; }
      #notfound .anchor_txt:hover {
        text-decoration: none; }
        #notfound .anchor_txt:hover::before {
          width: 100%;
          border-bottom: 1px solid #0a5fc7;
          transition: .2s; }
  #notfound .p_txt {
    font-size: 16px;
    line-height: 2em;
    text-align: center; }
  #notfound .p_txt_search {
    padding: 4.375em 0 1.875em; }
  #notfound .search_keyword {
    max-width: 660px;
    margin: 0 auto; }
    #notfound .search_keyword_submit {
      right: 2em; }
  #notfound .search_input_keyword {
    width: 100%;
    margin: 0;
    padding: .93em 2.5em;
    outline: none;
    font-size: 16px; }
    #notfound .search_input_keyword::placeholder {
      font-size: 16px; }
    #notfound .search_input_keyword:focus::placeholder {
      color: transparent; } }

/* 英語用 */
html[lang="en"] #notfound .notfound_main {
  margin: 0 0 calc((75 / 750) * 100vw); }

@media screen and (min-width: 750.1px) {
  html[lang="en"] #notfound .notfound_main {
    margin: 0 0 54px; } }
